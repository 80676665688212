figure.photo {
    height: 75rem;
    margin-right: 5rem;
  }
  
  .info {
    margin-top: 0;
    & span {
      text-transform: uppercase;
      font-size: 1.6rem;
      letter-spacing: 1px;
      font-weight: 500;
    }
  
    & h1 {
      margin: 1rem 0 4rem 0;
      font-size: 5rem;
      line-height: 1.2;
      font-weight: 500;
    }
  
    & p {
      font-size: 1.4rem;
      line-height: 2;
      color: #ccc;
      text-align: justify;
    }
    & li {
        font-size: 1.4rem;
        line-height: 2;
        color: #ccc;
        list-style-type: square;
        list-style-position: inside;
        text-align: justify;
      }
  }
  
  .step {
    border: 1px solid;
    padding: 3rem;
    height: 100%;
  
    & span {
      display: inline-block;
      text-transform: uppercase;
      padding: 0.5rem 1.2rem;
      letter-spacing: 1px;
      font-weight: 600;
      transform: translateY(-4.5rem);
    }
  
    & h3 {
      font-size: 2.5rem;
      line-height: 1.2;
      font-weight: 500;
      margin-bottom: 2rem;
      margin-top: -1.5rem;
    }
  }
  
  @media (max-width: 992px) {
    figure.photo {
      margin-right: 0;
    }
  
    .step h3 {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 576px) {
    .wrapper:not(:last-of-type) {
      margin-bottom: 3rem;
    }
  }
  